import { useNavigation, useRoute } from '@react-navigation/native';
import { ReactNode, useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import {
    ActivityIndicator,
    SafeAreaView,
    ScrollView,
    StyleProp,
    StyleSheet,
    View,
    ViewStyle
} from 'react-native';

import { auth } from '../../core/firestore';
import { colors } from '../../styles/colors';

interface PageProps {
    children: ReactNode;
    style?: StyleProp<ViewStyle>;
}

export const pageConstants = {
    pagePadding: 12,
    rowsGap: 24
};

export const Page = (props: PageProps) => {
    const navigation = useNavigation();
    const route = useRoute();

    const [user, loading, error] = useAuthState(auth);

    const isAuthPage = route.name === 'Auth';

    useEffect(() => {
        if (!loading && !user && !isAuthPage) {
            console.log('navigate to auth');
            navigation.navigate('Settings', { screen: 'Auth' });
        }

        if (user && isAuthPage) {
            navigation.navigate('Home');
        }
    }, [loading, user, route.name]);

    const { children, style } = props;

    if (loading) {
        <ActivityIndicator size="large" color={colors.white_1} />;
    }

    if (!loading && !user && !isAuthPage) {
        return null;
    }

    return (
        <SafeAreaView style={[styles.page, style]}>
            <ScrollView>
                <View style={styles.content}>{children}</View>
            </ScrollView>
        </SafeAreaView>
    );
};

const styles = StyleSheet.create({
    page: {
        backgroundColor: colors.blueDark_1,
        minHeight: '100%',
        maxHeight: '100%'
    },
    content: {
        paddingBottom: pageConstants.pagePadding,
        paddingTop: pageConstants.pagePadding
    }
});
