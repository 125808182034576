import { makeObservable } from 'mobx';

import { DateISO } from '../../common/types/date';
import { Model } from '../utils/Model';
import { RootModel } from './index';

export interface Drug {
    name: string;
    id: number;
}

export interface DrugTakeParams {
    drugId: number;
    drugGroupId: number;
    date: DateISO;
}

export class DrugModel extends Model<Drug> {
    rootModel: RootModel;

    constructor(rootModel: RootModel) {
        super({
            apiUrl: 'drugs'
        });

        this.rootModel = rootModel;

        makeObservable(this);
    }
}
