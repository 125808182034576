import { makeObservable } from 'mobx';

import { Model } from '../utils/Model';
import { RootModel } from './index';

export interface DrugCourse {
    startDate: string;
    endDate: string;
    drugId: number;
    groupId: number;
}

export class DrugCourseModel extends Model<DrugCourse> {
    rootModel: RootModel;

    constructor(rootModel: RootModel) {
        super({
            apiUrl: 'drug-courses'
        });

        this.rootModel = rootModel;

        makeObservable(this);
    }
}
