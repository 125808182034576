import { Text } from '@ui-kitten/components';
import { Pressable, StyleProp, StyleSheet, ViewStyle } from 'react-native';

import { DateISO } from '../../../../common/types/date';
import { borders } from '../../../styles/borders';
import { colors } from '../../../styles/colors';

interface ItemParsedDate {
    dayNumber: number;
    dayName: string;
}

export interface ItemProps {
    date: DateISO;
    active: boolean;
    style: StyleProp<ViewStyle>;
    onClick: VoidFunction;
    today?: boolean;
}

const dayByNumber: Record<number, string> = {
    1: 'Mon',
    2: 'Tue',
    3: 'Wed',
    4: 'Thu',
    5: 'Fri',
    6: 'Sat',
    0: 'Sun'
};

const parseDate = (date: DateISO): ItemParsedDate => {
    const dateObj = new Date(date);
    const day = dateObj.getDay();
    const dayNumber = dateObj.getDate();

    return {
        dayName: dayByNumber[day],
        dayNumber: dayNumber
    };
};

export const Item = (props: ItemProps) => {
    const { date, active, style, onClick, today } = props;

    const dateParsed = parseDate(date);

    return (
        <Pressable
            style={[
                styles.item,
                style,
                today ? styles.item_today : {},
                active ? styles.item_active : {}
            ]}
            onPress={onClick}
        >
            <Text category={'p1'} style={styles.day}>
                {dateParsed.dayName}
            </Text>
            <Text
                category={'h3'}
                style={[styles.number, active ? styles.number_active : {}]}
            >
                {dateParsed.dayNumber}
            </Text>
        </Pressable>
    );
};

const styles = StyleSheet.create({
    item: {
        width: 60,
        height: 60,
        borderColor: colors.white_1,
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: borders.m,
        padding: 4,
        alignItems: 'center',
        justifyContent: 'center'
    },
    item_active: {
        backgroundColor: colors.white_1
    },
    item_today: {
        backgroundColor: colors.white_1_transparent_15
    },
    day: {
        color: colors.text_gray_1
    },
    number: {
        color: colors.white_1
    },
    number_active: {
        color: colors.text_dark_1
    }
});
