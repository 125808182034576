import { DrugModel } from './drug';
import { DrugCourseModel } from './drugCourse';
import { DrugGroupModel } from './drugGroup';
import { DrugInGroupModel } from './drugInGroup';
import { TakenDrugsModel } from './takenDrug';

// Root store for cross-model data
export class RootModel {
    drug: DrugModel;

    drugGroup: DrugGroupModel;

    takenDrug: TakenDrugsModel;

    drugInGroup: DrugInGroupModel;

    drugCourse: DrugCourseModel;

    constructor() {
        this.drug = new DrugModel(this);
        this.drugGroup = new DrugGroupModel(this);
        this.takenDrug = new TakenDrugsModel(this);
        this.drugInGroup = new DrugInGroupModel(this);
        this.drugCourse = new DrugCourseModel(this);
    }
}

export const rootModel = new RootModel();
