import * as eva from '@eva-design/eva';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { ApplicationProvider, Icon, IconRegistry } from '@ui-kitten/components';
import { EvaIconsPack } from '@ui-kitten/eva-icons';
import { registerRootComponent } from 'expo';
import { useAuthState } from 'react-firebase-hooks/auth';
import { TailwindProvider } from 'tailwind-rn';

import { auth } from './src/core/firestore';
import { StoreProvider } from './src/hooks/useStore';
import { AddDrugPage } from './src/pages/AddDrug/AddDrug.page';
import { AddDrugGroupPage } from './src/pages/AddDrugGroup/AddDrugGroup.page';
import { AuthPage } from './src/pages/Auth/Auth.page';
import { MainPage } from './src/pages/Drugs/Main.page';
import { SettingsPage } from './src/pages/Settings/Settings.page';
import { Stat } from './src/pages/Stat/Stat';
import { colors } from './src/styles/colors';
import { iconSizes } from './src/styles/iconSizes';
import utilities from './tailwind.json';

const Stack = createNativeStackNavigator();
const Tab = createBottomTabNavigator();

const theme = {
    'color-info-500': '#fff'
};

const SettingsStackNavigation = () => {
    return (
        <Stack.Navigator
            initialRouteName="Settings"
            screenOptions={{
                headerShown: false
            }}
        >
            <Stack.Screen name="Settings" component={SettingsPage} />
        </Stack.Navigator>
    );
};

const HomeStackNavigation = () => {
    const [a] = useAuthState(auth);

    return (
        <Stack.Navigator
            initialRouteName="Home"
            screenOptions={{
                headerShown: false
            }}
        >
            <Stack.Screen name="Home" component={MainPage} />
            <Stack.Screen name="Home/AddDrug" component={AddDrugPage} />
            <Stack.Screen
                name="Home/AddDrugGroup"
                component={AddDrugGroupPage}
            />
            <Stack.Screen name="Stat" component={Stat} />
        </Stack.Navigator>
    );
};

const App = () => {
    const [user] = useAuthState(auth);

    return (
        <TailwindProvider utilities={utilities}>
            <ApplicationProvider {...eva} theme={{ ...eva.light, ...theme }}>
                <IconRegistry icons={EvaIconsPack} />
                <StoreProvider>
                    <NavigationContainer options={{}}>
                        {user ? (
                            <Tab.Navigator
                                // initialRouteName={'Auth'}
                                screenOptions={{
                                    tabBarShowLabel: false,
                                    headerShown: false,
                                    tabBarStyle: {
                                        backgroundColor: colors.blueDark_1,
                                        borderTopColor: colors.white_1,
                                        opacity: user ? 1 : 0.5
                                    },
                                    tabBarActiveTintColor: colors.white_1,
                                    tabBarInactiveTintColor:
                                        colors.white_1_transparent_50
                                }}
                            >
                                <Tab.Screen
                                    name="Home"
                                    component={HomeStackNavigation}
                                    options={{
                                        tabBarIcon: ({ color }) => (
                                            <Icon
                                                name={
                                                    'checkmark-square-2-outline'
                                                }
                                                fill={color}
                                                width={iconSizes.l}
                                                height={iconSizes.l}
                                            />
                                        )
                                    }}
                                />
                                <Tab.Screen
                                    name={'List'}
                                    component={() => null}
                                    options={{
                                        tabBarIcon: ({ color }) => (
                                            <Icon
                                                name={'list-outline'}
                                                fill={color}
                                                width={iconSizes.l}
                                                height={iconSizes.l}
                                            />
                                        )
                                    }}
                                />
                                <Tab.Screen
                                    name={'Settings'}
                                    component={SettingsStackNavigation}
                                    options={{
                                        tabBarIcon: ({ color }) => (
                                            <Icon
                                                name={'settings-outline'}
                                                fill={color}
                                                width={iconSizes.l}
                                                height={iconSizes.l}
                                            />
                                        )
                                    }}
                                />
                            </Tab.Navigator>
                        ) : (
                            <Stack.Navigator
                                screenOptions={{
                                    headerShown: false
                                }}
                            >
                                <Stack.Screen
                                    name={'Auth'}
                                    component={AuthPage}
                                />
                            </Stack.Navigator>
                        )}
                    </NavigationContainer>
                </StoreProvider>
            </ApplicationProvider>
        </TailwindProvider>
    );
};

registerRootComponent(App);
