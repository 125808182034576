import { addDoc } from '@firebase/firestore';
import { useNavigation } from '@react-navigation/native';
import { Input } from '@ui-kitten/components';
import { useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { View } from 'react-native';

import MyButton from '../../components/Button/Button';
import { Page, pageConstants } from '../../components/Page/Page';
import { auth, drugGroupsRef } from '../../core/firestore';

export const AddDrugGroupPage = () => {
    const [inputsState, setInputsState] = useState({
        name: ''
    });

    const [user] = useAuthState(auth);

    const navigation = useNavigation();

    console.log(auth.currentUser);

    return (
        <Page>
            <View
                style={{
                    paddingHorizontal: pageConstants.pagePadding
                }}
            >
                <View style={{ marginBottom: pageConstants.rowsGap }}>
                    <MyButton
                        label={'Back'}
                        theme={'one'}
                        icon="arrow-ios-back-outline"
                        onPress={() => {
                            navigation.navigate('Home');
                        }}
                    />
                </View>
                <Input
                    required
                    value={inputsState.name}
                    placeholder="Name"
                    onChangeText={(nextValue) =>
                        setInputsState({
                            ...inputsState,
                            name: nextValue
                        })
                    }
                />
                <View style={{ marginTop: 12 }}>
                    <MyButton
                        disabled={!inputsState.name.length}
                        label={'Save'}
                        theme={'two'}
                        onPress={async () => {
                            await addDoc(drugGroupsRef, {
                                ...(inputsState ?? {}),
                                access: [user.uid]
                            });
                            navigation.navigate('Home');
                        }}
                    />
                </View>
            </View>
        </Page>
    );
};
