import { useLocalObservable } from 'mobx-react-lite';
import React from 'react';

import { RootModel, rootModel } from '../models';

const storeContext = React.createContext<RootModel | null>(null);

export const StoreProvider = ({ children }: { children: React.ReactNode }) => {
    const store = useLocalObservable(() => rootModel);
    return (
        <storeContext.Provider value={store}>{children}</storeContext.Provider>
    );
};

/**
 * Hook for using mobx store
 *
 * You can use selector callback
 */
export const useStore = (callback?: (store: RootModel) => any) => {
    const store = React.useContext(storeContext);
    if (!store) {
        // this is especially useful in TypeScript so you don't need to be checking for null all the time
        throw new Error('useStore must be used within a StoreProvider.');
    }
    return callback ? callback(store) : store;
};
