import { NavigationProp, useNavigation } from '@react-navigation/native';
import { Icon, MenuItem, OverflowMenu, Text } from '@ui-kitten/components';
import { format } from 'date-fns';
import { useState } from 'react';
import { Pressable, StyleSheet, View } from 'react-native';

import { pageConstants } from '../../../components/Page/Page';
import { colors } from '../../../styles/colors';
import { iconSizes } from '../../../styles/iconSizes';

interface HeaderProps extends NavigationProp<any> {
    date: string;
}

export const Header = (props: HeaderProps) => {
    const { date } = props;

    const navigation = useNavigation();

    const [addMenuVisible, setAddMenuVisible] = useState(false);

    const onAddItemSelect = () => {
        setAddMenuVisible(false);
    };

    const handleAddBtnClick = () => {
        setAddMenuVisible(!addMenuVisible);
    };

    return (
        <View style={styles.header}>
            <Text category={'h6'} style={styles.date}>
                🗓️ {format(new Date(date), 'EEEE, d MMM')}
            </Text>

            <OverflowMenu
                anchor={() => (
                    <Pressable onPress={handleAddBtnClick}>
                        <Icon
                            name={'plus'}
                            fill={colors.white_1}
                            style={styles.plusButton}
                        />
                    </Pressable>
                )}
                visible={addMenuVisible}
                onSelect={onAddItemSelect}
                onBackdropPress={() => setAddMenuVisible(false)}
            >
                <MenuItem
                    title="Add Drug"
                    onPress={() => {
                        navigation.navigate('Home/AddDrug', {
                            mainPageDate: date
                        });
                    }}
                />
                <MenuItem
                    title="Add Group"
                    onPress={() => {
                        navigation.navigate('Home/AddDrugGroup', {
                            mainPageDate: date
                        });
                    }}
                />
            </OverflowMenu>
        </View>
    );
};

const styles = StyleSheet.create({
    header: {
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        paddingLeft: pageConstants.pagePadding,
        paddingRight: pageConstants.pagePadding
    },
    date: {
        flexGrow: 1,
        color: colors.white_1
    },
    plusButton: {
        width: iconSizes.l,
        height: iconSizes.l
    }
});
