import { useNavigation } from '@react-navigation/native';
import { Icon, MenuItem, OverflowMenu } from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { Pressable, StyleSheet, View, Text } from 'react-native';
import { useTailwind } from 'tailwind-rn';

import { borders } from '../../../../styles/borders';
import { colors } from '../../../../styles/colors';
import { iconSizes } from '../../../../styles/iconSizes';
import { CardTag } from './CardTag/CardTag';
import { DrugItemProps } from './DrugItem.types';

export const DrugItem = observer((props: DrugItemProps) => {
    const { name, isChecked, onPress, tags, id, date, groupId } = props;

    const tw = useTailwind();

    const [isPressed, setIsPressed] = useState(false);
    const [itemMenuVisible, setItemsMenuVisible] = useState(false);

    const navigation = useNavigation();

    const handlePress = () => {
        onPress();
    };

    const handleTouchStart = () => {
        setIsPressed(true);
    };

    const handleTouchEnd = () => {
        setIsPressed(false);
    };

    return (
        <OverflowMenu
            style={{
                marginTop: 8
            }}
            placement={'bottom end'}
            backdropStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
            anchor={() => (
                <Pressable
                    onLongPress={() => setItemsMenuVisible(true)}
                    onTouchStart={handleTouchStart}
                    onTouchEnd={handleTouchEnd}
                    onPress={handlePress}
                    style={[styles.card, isPressed ? styles.card_pressed : {}]}
                >
                    <View
                        style={{
                            flexDirection: 'row',
                            alignItems: 'center'
                        }}
                    >
                        <Text style={tw('grow text-blue-1 text-xl')}>
                            {name}
                        </Text>
                        <View
                            style={{
                                height: iconSizes.l,
                                width: iconSizes.l
                            }}
                        >
                            {isChecked && (
                                <Icon
                                    style={{
                                        width: '100%',
                                        height: '100%'
                                    }}
                                    fill={colors.blue_1}
                                    name={'checkmark-circle-2'}
                                />
                            )}
                        </View>
                    </View>
                    {Boolean(tags?.length) && (
                        <View style={[tw('flex-row'), { flexWrap: 'wrap' }]}>
                            {tags.map((tag, i) => (
                                <CardTag
                                    key={i}
                                    {...tag}
                                    style={[i !== 0 ? { marginLeft: 8 } : {}]}
                                />
                            ))}
                        </View>
                    )}
                </Pressable>
            )}
            visible={itemMenuVisible}
            onSelect={() => setItemsMenuVisible(false)}
            onBackdropPress={() => setItemsMenuVisible(false)}
        >
            <MenuItem
                title="Edit Drug"
                accessoryLeft={(itemProps) => (
                    <Icon {...itemProps} name={'edit-2-outline'} />
                )}
                onPress={() => {
                    navigation.navigate('Home/AddDrug', {
                        editItemId: id,
                        mainPageDate: date,
                        editGroupId: groupId
                    });
                }}
            />
        </OverflowMenu>
    );
});

const styles = StyleSheet.create({
    card: {
        padding: 12,
        borderRadius: borders.m,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: colors.blue_1,
        marginTop: 16
    },
    card_pressed: {
        backgroundColor: colors.blue_1_transparent_10
    }
});
