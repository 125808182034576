import { Text } from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';

import MyButton from '../../components/Button/Button';
import { Page } from '../../components/Page/Page';
import { auth } from '../../core/firestore';

export const SettingsPage = observer(() => {
    const handleLogout = () => {
        auth.signOut();
    };

    return (
        <Page>
            <Text>Settings</Text>
            <MyButton label={'Logout'} onPress={handleLogout} />
        </Page>
    );
});
