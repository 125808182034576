import { Icon } from '@ui-kitten/components';
import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { useState } from 'react';
import { Text } from 'react-native';

import MyButton from '../../components/Button/Button';
import { Page } from '../../components/Page/Page';
import { firebaseErrors } from '../../constants/firebaseErrors';
import { auth } from '../../core/firestore';
import { colors } from '../../styles/colors';
import { iconSizes } from '../../styles/iconSizes';

export const AuthPage = () => {
    const [errorMessage, setErrorMessage] = useState('');
    const [hasError, setHasError] = useState(false);

    console.log('auth page');

    /**
     * Signing in with Google in popup window
     */
    const handleSignIn = async () => {
        try {
            const provider = new GoogleAuthProvider();
            provider.setCustomParameters({
                client_id:
                    'com.googleusercontent.apps.166976050901-cp7vhhgaadlt0v42slh5nh9nojtqks04'
            });
            await signInWithPopup(auth, provider);
        } catch (error) {
            const message = firebaseErrors[error.code];
            if (message) {
                setErrorMessage(message);
            }
            setHasError(true);
            console.error(error);
        }
    };

    return (
        <Page>
            <Icon
                name={'person-outline'}
                style={{
                    width: iconSizes.l,
                    height: iconSizes.l
                }}
                fill={colors.white_1}
            />
            {hasError && <Text style={{ color: 'white' }}>{errorMessage}</Text>}
            <MyButton
                style={{ marginTop: 24 }}
                theme={'two'}
                onPress={handleSignIn}
                label={'Login'}
            />
        </Page>
    );
};
