import { observer } from 'mobx-react-lite';
import {
    ScrollView,
    StyleProp,
    StyleSheet,
    View,
    ViewStyle
} from 'react-native';

import { DateISO } from '../../../common/types/date';
import { DateLocal } from '../../../common/utils/getCurrentDate';
import { pageConstants } from '../Page/Page';
import { Item } from './Item/Item';

export interface DatepickerHorizontalProps {
    onChange: (date: DateISO) => void;
    style: StyleProp<ViewStyle>;
    date: DateISO;
}

interface DatepickerDatesConfig {
    date: DateISO;
    day: number;
    month: number;
}

const oneMonthDates: DatepickerDatesConfig[] = (() => {
    const dates: DatepickerDatesConfig[] = [];
    const currentDate = new Date();
    const daysInMonth = DateLocal.getDaysInMonth(currentDate);
    const monthNumber = currentDate.getMonth();

    let i = 1;

    while (i <= daysInMonth) {
        dates.push({
            date: new Date(currentDate.setDate(i)).toISOString() as DateISO,
            day: i,
            month: monthNumber
        });

        i++;
    }

    return dates;
})();

export const DatepickerHorizontal = observer(
    (props: DatepickerHorizontalProps) => {
        const { style, date = DateLocal.current, onChange } = props;

        const chosenDateNumber = new Date(date).getDate();
        const chosenMonthNumber = new Date(date).getMonth();

        const today = new Date();

        return (
            <ScrollView
                horizontal={true}
                showsHorizontalScrollIndicator={false}
                style={style}
            >
                <View style={styles.scrollContainer}>
                    {oneMonthDates.map(({ day, month, ...params }, i) => (
                        <Item
                            key={i}
                            date={params.date}
                            active={
                                chosenDateNumber === day &&
                                chosenMonthNumber === month
                            }
                            today={
                                DateLocal.dayOnly(params.date) ===
                                DateLocal.dayOnly(today)
                            }
                            style={{ marginLeft: i > 0 ? 8 : 0 }}
                            onClick={() => onChange(params.date)}
                        />
                    ))}
                </View>
            </ScrollView>
        );
    }
);

const styles = StyleSheet.create({
    scrollContainer: {
        flexDirection: 'row',
        paddingLeft: pageConstants.pagePadding,
        paddingRight: pageConstants.pagePadding
    }
});
