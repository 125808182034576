import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { StyleSheet } from 'react-native';

import { DateISO } from '../../../common/types/date';
import { DateLocal } from '../../../common/utils/getCurrentDate';
import { DatepickerHorizontal } from '../../components/DatepickerHorizontal/DatepickerHorizontal';
import { Page } from '../../components/Page/Page';
import { Drugs } from './DrugsList/Drugs';
import { Header } from './Header/Header';

export const MainPage = observer(() => {
    const [selectedDate, setSelectedDate] = useState<DateISO>(
        DateLocal.current
    );

    return (
        <Page>
            <Header date={selectedDate} />
            <DatepickerHorizontal
                style={styles.datepicker}
                date={selectedDate}
                onChange={(date) => {
                    setSelectedDate(date);
                }}
            />
            <Drugs date={selectedDate} />
        </Page>
    );
});

const styles = StyleSheet.create({
    text: {
        fontSize: 20
    },
    mainPage: {
        marginTop: 20,
        padding: 15,
        height: '100%'
    },
    datepicker: {
        marginTop: 10
    }
});
