import { computed, makeObservable } from 'mobx';

import { Model } from '../utils/Model';
import { Drug } from './drug';
import { RootModel } from './index';

interface DrugGroup {
    name: string;
    id: number;
}

export type DrugGroupWithDrugs = DrugGroup & { drugs: Drug[] };

export class DrugGroupModel extends Model<DrugGroup> {
    rootModel: RootModel;

    constructor(rootModel: RootModel) {
        super({
            apiUrl: 'drug-groups'
        });

        this.rootModel = rootModel;

        makeObservable(this);
    }

    @computed
    get groupsWithDrugs() {
        return this.items
            .map((group) => ({
                ...group,
                drugs: this.rootModel.drugInGroup.items
                    .filter((drugInGroup) => drugInGroup.groupId == group.id)
                    .reduce((acc, drugInGroup) => {
                        const item = this.rootModel.drug.items.find(
                            (drug) => drug.id === drugInGroup.drugId
                        );

                        if (item) {
                            acc.push(item);
                        }
                        return acc;
                    }, [] as Drug[])
            }))
            .filter((group) => group.drugs.length);
    }
}
