import axios from 'axios';
// import { Toast } from 'toastify-react-native';

axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        console.error('error', error);
        // Toast.error(error.message);
        throw error;
    }
);

export const http = axios;
