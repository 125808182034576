import moment from 'moment';

import { DateISO } from '../types/date';

export class DateLocal {
    static oneDateMilliseconds = 1000 * 60 * 60 * 24;

    static get current(): DateISO {
        return new Date().toUTCString() as DateISO;
    }

    static format(date: string | Date): DateISO {
        return new Date(date).toUTCString() as DateISO;
    }

    /**
     * Round to one day
     * Reset minutes, hours and sec
     */
    static round(date: string | Date): DateISO {
        const time = new Date(date).getTime();
        const roundedMilliseconds = time - (time % this.oneDateMilliseconds);
        const res = new Date(roundedMilliseconds).toUTCString() as DateISO;

        return res;
    }

    static addDays(date: string | Date, days: number): DateISO {
        const milleseconds =
            new Date(date).getTime() + this.oneDateMilliseconds * days;

        const iso = new Date(milleseconds).toUTCString() as DateISO;

        return iso;
    }

    /**
     * Funciton that return number of days in month
     */
    static getDaysInMonth(date: string | Date) {
        return moment(date).daysInMonth();
    }

    static get currentRounded() {
        return this.round(this.current);
    }

    static dayOnly(date: string | Date) {
        const res = moment(date).format('YYYY-M-D');
        return res;
    }
}
