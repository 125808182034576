import { addDoc, query } from '@firebase/firestore';
import { useNavigation } from '@react-navigation/native';
import {
    Input,
    SelectItem,
    Select,
    Text,
    IndexPath,
    Datepicker
} from '@ui-kitten/components';
import { endOfDay, startOfDay } from 'date-fns';
import { where } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import {
    useCollection,
    useCollectionData
} from 'react-firebase-hooks/firestore';
import { View, StyleSheet } from 'react-native';

import { DrugGroup } from '../../../api/src/services/drugGroups/drugGroup.entity';
import MyButton from '../../components/Button/Button';
import { Page, pageConstants } from '../../components/Page/Page';
import {
    auth,
    drugCoursesRef,
    drugGroupsRef,
    drugsInGroupsRef,
    drugsRef
} from '../../core/firestore';
import { Drug } from '../../models/drug';
import { colors } from '../../styles/colors';

interface InputsState {
    name: string;
    groupInputIndex: IndexPath | IndexPath[];
    startDate: string | Date | null;
    endDate: string | Date | null;
}

export const AddDrugPage = (props: any) => {
    const { route } = props;
    const { mainPageDate, editItemId, editGroupId } = route?.params || {};
    const [user] = useAuthState(auth);
    const [drugGroupsList, drugGroupsLoading, drugGroupsSnapshot] =
        useCollectionData(
            query(drugGroupsRef, where('access', 'array-contains', user.uid))
        );

    const [drugGroupsCollection] = useCollection(
        query(drugGroupsRef, where('access', 'array-contains', user.uid))
    );

    const [selectedDrugItem, setSelectedDrugItem] = useState<Drug | null>(null);
    const [selectedDrugCourseItem, setSelectedDrugCourseItem] =
        useState<DrugGroup | null>(null);

    const [inputsState, setInputsState] = useState<InputsState>({
        startDate: new Date()
    } as InputsState);

    const navigation = useNavigation();

    useEffect(() => {
        (async () => {
            if (editItemId) {
                // const drugItem = await rootModel.drug.byId(editItemId);
                // const courseItem = await rootModel.drugCourse.fetch({
                //     drugId: editItemId,
                //     date: mainPageDate
                // });
                //
                // setSelectedDrugItem(drugItem);
                // setSelectedDrugCourseItem(courseItem?.[0]);
                //
                // const selectedGroupIndex = rootModel.drugGroup.items.findIndex(
                //     (el) => el.id === editGroupId
                // );
                //
                // setInputsState({
                //     ...inputsState,
                //     name: drugItem.name,
                //     groupInputIndex: new IndexPath(selectedGroupIndex ?? 0),
                //     startDate: courseItem[0].startDate
                //         ? new Date(courseItem[0].startDate)
                //         : null,
                //     endDate: courseItem[0].endDate
                //         ? new Date(courseItem[0].endDate)
                //         : null
                // });
            }
        })();
    }, []);

    if (
        drugGroupsLoading

        // rootModel.drug.pending ||
        // rootModel.drugGroup.pending ||
        // rootModel.drugInGroup.pending ||
        // rootModel.drugCourse.pending
    ) {
        return <Text>Loading</Text>;
    }

    const onsubmit = async () => {
        const drugGroupDoc =
            drugGroupsCollection?.docs[inputsState.groupInputIndex.row] ?? [];

        const startDate = inputsState.startDate
            ? startOfDay(new Date(inputsState.startDate))
            : startOfDay(new Date(mainPageDate));
        // By default is selected in horizontal calendar day
        const endDate = inputsState.endDate
            ? endOfDay(new Date(inputsState.endDate))
            : null;

        if (editItemId) {
            // const drugInGroupItem = await rootModel.drugInGroup.items.find(
            //     (el) => el.drugId === editItemId && el.groupId === editGroupId
            // );
            //
            // if (
            //     !selectedDrugItem ||
            //     !drugInGroupItem ||
            //     !selectedDrugCourseItem
            // ) {
            //     error("Can't update drug item");
            //     return;
            // }
            //
            // // Edit existing drug
            // await rootModel.drug.update(selectedDrugItem.id, {
            //     name: inputsState.name
            // });
            //
            // await rootModel.drugInGroup.update(drugInGroupItem.id, {
            //     groupId: drugGroupId
            // });
            //
            // console.log('selectedDrugCourseItem', selectedDrugCourseItem);
            //
            // await rootModel.drugCourse.update(selectedDrugCourseItem.id, {
            //     groupId: drugGroupId,
            //     startDate,
            //     endDate
            // });
        } else {
            debugger;

            const createdDrug = await addDoc(drugsRef, {
                name: inputsState.name,
                access: [user.uid]
            });

            await Promise.all([
                addDoc(drugsInGroupsRef, {
                    drug: createdDrug,
                    group: drugGroupDoc.ref,
                    access: [user.uid]
                }),
                addDoc(drugCoursesRef, {
                    drug: createdDrug,
                    group: drugGroupDoc.ref,
                    // FIXME: Now have a bug with timezones
                    //  Need to remove timezones from datea
                    startDate,
                    endDate,
                    access: [user.uid]
                })
            ]);
        }

        navigation.navigate('Home');
    };

    return (
        <Page>
            <View
                style={{
                    paddingHorizontal: pageConstants.pagePadding
                }}
            >
                <View style={{ marginBottom: pageConstants.rowsGap }}>
                    <MyButton
                        label={'Back'}
                        theme={'one'}
                        icon="arrow-ios-back-outline"
                        onPress={() => {
                            navigation.navigate('Home');
                        }}
                    />
                </View>
                <Input
                    style={{}}
                    value={inputsState?.name ?? ''}
                    placeholder="Name"
                    onChangeText={(nextValue) =>
                        setInputsState({
                            ...inputsState,
                            name: nextValue
                        })
                    }
                />
                <Select
                    caption={
                        !drugGroupsList?.length
                            ? 'You have no groups yet'
                            : null
                    }
                    style={{ marginTop: 12 }}
                    selectedIndex={inputsState?.groupInputIndex}
                    value={
                        inputsState?.groupInputIndex
                            ? drugGroupsList[inputsState?.groupInputIndex - 1]
                                  .name
                            : null
                    }
                    onSelect={(index) => {
                        setInputsState({
                            ...inputsState,
                            groupInputIndex: index
                        });
                    }}
                    disabled={!drugGroupsList?.length}
                >
                    {drugGroupsList?.map((drugGroup, i) => (
                        <SelectItem key={i} title={drugGroup.name} />
                    )) ?? []}
                </Select>
                <Text
                    category={'p1'}
                    style={{ color: colors.white_1, marginTop: 12 }}
                >
                    Datesoup
                </Text>
                <View style={styles.rangeContainer}>
                    <Datepicker
                        style={styles.rangePicker}
                        placeholder="Start Date"
                        date={inputsState.startDate ?? null}
                        onSelect={(nextDate) => {
                            setInputsState({
                                ...inputsState,
                                startDate: nextDate
                            });
                        }}
                    />

                    <Datepicker
                        style={[styles.rangePicker, { marginLeft: 12 }]}
                        placeholder="End Date"
                        date={inputsState.endDate ?? null}
                        onSelect={(nextDate) => {
                            setInputsState({
                                ...inputsState,
                                endDate: nextDate
                            });
                        }}
                    />
                </View>
                <MyButton
                    style={{ marginTop: 24 }}
                    disabled={!inputsState.name?.length}
                    theme={'two'}
                    onPress={onsubmit}
                    label={'Save'}
                />
            </View>
        </Page>
    );
};

const styles = StyleSheet.create({
    rangeContainer: {
        flexDirection: 'row',
        marginTop: 12
    },
    rangePicker: {
        flex: 1
    }
});
