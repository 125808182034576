import { action, computed, makeObservable } from 'mobx';

import { DateISO } from '../../common/types/date';
import { Model } from '../utils/Model';
import { DrugTakeParams } from './drug';
import { RootModel } from './index';

// @ts-ignore

// const get = require('lodash.get');

export interface TakenDrug {
    checkedTime: string;
    // takeTime: string;
    drugId: number;
    drugGroupId: number;
}

// GroupId => DrugId => bolean
export type DrugsTakenObj = Record<string, Record<string, boolean>>;

export class TakenDrugsModel extends Model<TakenDrug> {
    rootModel: RootModel;

    constructor(rootModel: RootModel) {
        super({
            apiUrl: 'taken-drugs'
        });

        this.rootModel = rootModel;

        makeObservable(this);
    }

    @action
    take({ drugId, drugGroupId, date }: DrugTakeParams) {
        const isChecked = this.items.some(
            (takenDrug) =>
                takenDrug.drugId === drugId &&
                takenDrug.drugGroupId === drugGroupId
        );

        if (isChecked) {
            // FIXME: Add Date for delete operation
            this.delete({
                drugId,
                drugGroupId,
                checkedTime: date
            });

            const updatedItems = this.items.filter(
                (item) =>
                    !(
                        item.drugId === drugId &&
                        item.drugGroupId === drugGroupId
                    )
            );

            this.items.replace(updatedItems);
        } else {
            this.create({
                checkedTime: date,
                drugId,
                drugGroupId
            });

            this.items.push({
                checkedTime: date,
                drugId,
                drugGroupId
            });
        }
    }

    @action
    takeAllInGroup({
        drugGroupId,
        date
    }: {
        drugGroupId: number;
        date: DateISO;
    }) {
        const drugsInGroup = this.rootModel.drugInGroup.items.filter(
            (drugInGroup) => drugInGroup.groupId === drugGroupId
        );

        const newItems: TakenDrug[] = drugsInGroup
            .map((drugInGroup) => ({
                checkedTime: date,
                drugId: drugInGroup.drugId,
                drugGroupId: drugInGroup.groupId
            }))
            // Filter out already taken drugs
            .filter(
                (item) =>
                    !this.items.find(
                        (takenDrug) =>
                            takenDrug.drugId === item.drugId &&
                            drugGroupId === item.drugGroupId
                    )
            );

        this.items.push(...newItems);

        this.create(newItems);
    }

    @computed
    get isAllDrugsInGroupTaken(): Record<string, boolean> {
        return this.rootModel.drugGroup.items.reduce((acc, drugGroup) => {
            const drugsInGroup = this.rootModel.drugInGroup.items.filter(
                (drugInGroup) => drugInGroup.groupId === drugGroup.id
            );

            const takenDrugs = this.items.filter(
                (takenDrug) => takenDrug.drugGroupId === drugGroup.id
            );

            acc[drugGroup.id] = takenDrugs.length === drugsInGroup.length;

            return acc;
        }, {} as Record<string, boolean>);
    }

    @computed
    get takenObj(): DrugsTakenObj {
        const set = (obj: Record<any, any>, path: string[], val: any) => {
            path.forEach((key, i) => {
                if (i === path.length - 1) {
                    obj[key] = val;
                } else {
                    obj[key] = obj[key] || {};
                    obj = obj[key];
                }
            });
        };

        const res = this.items.reduce((acc, takenDrug) => {
            set(
                acc,
                [takenDrug.drugGroupId.toString(), takenDrug.drugId.toString()],
                true
            );

            return acc;
        }, {} as DrugsTakenObj);

        return res;
    }
}
