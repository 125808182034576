import { APP_ENV, BASE_API_URL_DEV, BASE_API_URL_PROD } from '@env';
import {
    action,
    computed,
    IObservableArray,
    makeObservable,
    observable,
    runInAction
} from 'mobx';

import { http } from './http';

export interface ModelParams {
    apiUrl: string;
}

export class Model<Item extends { id: number }> {
    apiUrl: string;

    headers = {
        'Content-Type': 'application/json'
    };

    state = observable({
        pending: false
    });

    @observable
    pending = false;

    @observable
    loaded = false;

    @observable
    items: IObservableArray<Item> = observable([]);

    @computed
    get noItems() {
        return !this.items.length;
    }

    constructor(params: ModelParams) {
        this.apiUrl = params.apiUrl;

        makeObservable(this);
    }

    @action
    async fetch(params?: Record<string, string | number>) {
        runInAction(() => {
            this.pending = true;
        });

        try {
            const response = await http(this.url, {
                params,
                headers: this.headers
            });

            const data = response.data;

            runInAction(() => {
                this.items.replace(data);

                this.loaded = true;
            });
        } catch (e) {
            console.error(e);
        }

        runInAction(() => {
            this.pending = false;
        });

        return this.items;
    }

    /**
     * Just find and returns if we have this item fetched and fetch if we not
     */
    @action
    async byId(id: number) {
        runInAction(() => {
            this.pending = true;
        });

        let item: Item | undefined = this.items.find((el) => el.id === id);

        if (!item) {
            try {
                const response = await http.get(`${this.url}/${id}`, {
                    headers: this.headers
                });

                item = response.data;

                runInAction(() => {
                    this.items.push(item as Item);
                });
            } catch (e) {
                console.error(e);
            }
        }

        runInAction(() => {
            this.pending = false;
        });

        return item;
    }

    @action
    async create(data: Omit<Item, 'id'>) {
        this.pending = true;
        let createData = null;

        try {
            // @ts-ignore FIXME type
            const response = await http.post(this.url, data, {
                headers: this.headers
            });

            createData = response.data;

            this.loaded = true;
        } catch (e) {
            console.error(e);
        }

        this.pending = false;
        return createData;
    }

    @action
    async delete(params: any) {
        this.pending = true;

        try {
            await http.delete(this.url, {
                params
            });
        } catch (e) {
            console.error(e);
        }

        this.pending = false;
    }

    @action
    async update(id: number, params?: any) {
        this.pending = true;

        const url = `${this.url}/${id}`;

        console.log('used  update url', url);

        try {
            await http.patch(`${this.url}/${id}`, params);
        } catch (e) {
            console.error(e);
        }

        this.pending = false;
    }

    get url() {
        return `${APP_ENV === 'prod' ? BASE_API_URL_PROD : BASE_API_URL_DEV}${
            this.apiUrl
        }`;
    }
}
