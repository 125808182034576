import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { StyleSheet, Text } from 'react-native';

import { Page } from '../../components/Page/Page';
import { rootModel } from '../../models';

interface StatProps {}

export const Stat = observer((props: StatProps) => {
    const {} = props;

    useEffect(() => {
        rootModel.drug.fetch();
    }, []);

    // @ts-ignore
    return (
        <Page>
            {rootModel.drug.pending ? (
                <Text style={styles.text}>Загрузка...</Text>
            ) : (
                <Text style={styles.text}>Загрузка завершена</Text>
            )}
            {
                // @ts-ignore
                rootModel.drug.items.map((drug) => (
                    <Text key={drug.id} style={styles.text}>
                        {drug.name}
                    </Text>
                ))
            }
        </Page>
    );
});

const styles = StyleSheet.create({
    container: {
        color: '#fff'
    },
    text: {
        color: '#fff',
        fontSize: 50
    }
});
