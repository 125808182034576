export const colors = {
    blueDark_1: '#212B36',
    blue_1: '#A1D2FF',
    blue_2: '#4A6783',
    blue_3: '#2F4961',
    blue_1_transparent_10: 'rgba(161, 210, 255, 0.1)',
    white_1_transparent_15: 'rgba(255,255,255, 0.15)',
    white_1_transparent_50: 'rgba(255,255,255, 0.5)',
    white_1: '#fff',
    text_gray_1: '#999999',
    text_dark_1: '#212B36'
};
