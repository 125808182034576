import { Icon, Text } from '@ui-kitten/components';
import { Pressable, StyleProp, ViewStyle } from 'react-native';
import { useTailwind } from 'tailwind-rn';

import { borders } from '../../../../../styles/borders';
import { colors } from '../../../../../styles/colors';
import { iconSizes } from '../../../../../styles/iconSizes';
import { observer } from "mobx-react";

export type CardTagProps = {
    text: string;
    icon: string;
    style: StyleProp<ViewStyle>;
};

export const CardTag = (props: CardTagProps) => {
    const { text, style, icon } = props;

    const tw = useTailwind();

    const handlePress = () => {};

    return (
        <Pressable
            onPress={handlePress}
            style={[
                {
                    borderStyle: 'solid',
                    borderRadius: borders.round,
                    borderColor: colors.blue_1,
                    borderWidth: 1,
                    paddingHorizontal: 12,
                    paddingVertical: 4,
                    marginTop: 8,
                    flexDirection: 'row',
                    alignItems: 'center'
                },
                style
            ]}
        >
            {icon && (
                <Icon
                    name={icon}
                    style={{
                        width: iconSizes.s,
                        height: iconSizes.s,
                        marginRight: 4
                    }}
                    fill={colors.blue_1}
                />
            )}
            <Text category={'p2'} style={{ color: colors.blue_1 }}>
                {text}
            </Text>
        </Pressable>
    );
};
