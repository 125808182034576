import { makeObservable } from 'mobx';

import { Model } from '../utils/Model';
import { RootModel } from './index';

interface DrugInGroup {
    groupId: number;
    drugId: number;
}

export class DrugInGroupModel extends Model<DrugInGroup> {
    rootModel: RootModel;

    constructor(rootModel: RootModel) {
        super({
            apiUrl: 'drugs-in-groups'
        });

        this.rootModel = rootModel;

        makeObservable(this);
    }
}
