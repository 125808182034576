import { Icon } from '@ui-kitten/components';
import React, { useState } from 'react';
import {
    Pressable,
    StyleProp,
    StyleSheet,
    Text,
    ViewStyle
} from 'react-native';

import { colors } from '../../styles/colors';
import { iconSizes } from '../../styles/iconSizes';

interface MyButtonProps {
    disabled?: boolean;
    iconName?: string;
    label: string;
    onPress: () => void;
    theme?: 'one' | 'two';
    variant?: 'outlined' | 'filled';
    icon?: string;
    style?: StyleProp<ViewStyle>;
}

const MyButton = (props: MyButtonProps) => {
    const { label, icon, disabled, onPress, style } = props;
    const buttonStyle = [styles.button];
    const [isPressed, setIsPressed] = useState(false);

    if (props.theme === 'one') {
        buttonStyle.push(styles.themeOne);

        if (isPressed) {
            buttonStyle.push(styles.themeOne_pressed);
        }
    } else if (props.theme === 'two') {
        buttonStyle.push(styles.themeTwo);

        if (isPressed) {
            buttonStyle.push(styles.themeTwo_pressed);
        }
    }

    const handlePress = () => {
        if (disabled) {
            return;
        }

        onPress?.();
    };

    return (
        <Pressable
            style={[buttonStyle, style]}
            onPress={handlePress}
            onPressIn={() => setIsPressed(true)}
            onPressOut={() => setIsPressed(false)}
        >
            {icon && (
                <Icon style={styles.icon} fill={colors.white_1} name={icon} />
            )}
            <Text style={styles.buttonText}>{label}</Text>
        </Pressable>
    );
};

const styles = StyleSheet.create({
    icon: {
        marginLeft: -5,
        width: iconSizes.m,
        height: iconSizes.m
    },
    button: {
        alignSelf: 'flex-start',
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 18,
        paddingVertical: 6,
        justifyContent: 'center',
        alignContent: 'center'
    },
    buttonText: {
        color: '#fff',
        fontSize: 16,
        marginLeft: 8
    },
    themeOne: {
        borderRadius: 999,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: colors.white_1
    },
    themeOne_pressed: {
        backgroundColor: colors.white_1_transparent_15
    },
    themeTwo: {
        borderRadius: 8,
        paddingVertical: 12,
        paddingHorizontal: 16,
        alignSelf: 'stretch',
        backgroundColor: colors.blue_2
    },
    themeTwo_pressed: {
        backgroundColor: colors.blue_3
    }
});

export default MyButton;
