import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore, collection } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: 'AIzaSyAmBZwm1wyZslcKEK5gdnNnNsyNf4_rlhM',
    authDomain: 'biohackapp.firebaseapp.com',
    projectId: 'biohackapp',
    storageBucket: 'biohackapp.appspot.com',
    messagingSenderId: '201552052637',
    appId: '1:201552052637:web:e6c52d8bd96870b6b3af74',
    measurementId: 'G-KE2Z4F3TSK'
};

export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);

export const drugsRef = collection(db, 'drugs');
export const drugGroupsRef = collection(db, 'drugGroups');
export const usersRef = collection(db, 'users');
export const drugsInGroupsRef = collection(db, 'drugsInGroups');
export const takenDrugsRef = collection(db, 'takenDrugs');
export const drugCoursesRef = collection(db, 'drugCourses');
