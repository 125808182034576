import { addDoc, deleteDoc } from '@firebase/firestore';
import { Text } from '@ui-kitten/components';
import { endOfDay, startOfDay } from 'date-fns';
import { getDoc, query, where, and } from 'firebase/firestore';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import {
    useCollection,
    useCollectionData
} from 'react-firebase-hooks/firestore';
import {
    ActivityIndicator,
    StyleProp,
    StyleSheet,
    View,
    ViewStyle
} from 'react-native';

import { DateISO } from '../../../../common/types/date';
import { pageConstants } from '../../../components/Page/Page';
import { auth, drugCoursesRef, takenDrugsRef } from '../../../core/firestore';
import { colors } from '../../../styles/colors';
import { DrugItem } from './DrugItem/DrugItem';
import { DrugsGroup } from './DrugsGroup/DrugsGroup';

export interface DrugsProps {
    date: DateISO;
    style?: StyleProp<ViewStyle>;
}

/**
 * Hight level drugs list component
 */
export const Drugs = observer((props: DrugsProps) => {
    const { date, style = {} } = props;

    const [user, loading, error] = useAuthState(auth);

    const [drugsInGroups, setDrugsInGroups] = useState([]);
    const [drugsReady, setDrugsReady] = useState(false);

    // HACK: Onle endDate filter here, because of limitations of firebase
    const [drugCourseListNoStartDate, drugCoursesLoading] = useCollectionData(
        // drugCoursesRef
        query(
            drugCoursesRef,
            and(
                // or(
                //     where('endDate', '==', null)
                //     // where('endDate', '>=', new Date(date))
                // ),
                where('access', 'array-contains', user?.uid)
            )
        )
    );

    console.log(
        'drugCourseListNoStartDate',
        user?.uid,
        drugCourseListNoStartDate
    );

    const [takenDrugsList, takenDrugsLoading] = useCollection(
        query(
            takenDrugsRef,
            and(
                where('date', '<=', endOfDay(new Date(date))),
                where('date', '>=', startOfDay(new Date(date))),
                where('access', 'array-contains', user?.uid)
            )
        )
    );

    // useEffect(() => {
    //     (async () => {
    //         const q = query(drugsRef);
    //         const querySnapshot = await getDocs(q);
    //         // querySnapshot.onSnapshot((querySnapshot) => {
    //         //     const updatedItems = querySnapshot.docs.map((doc) =>
    //         //         doc.data()
    //         //     );
    //         //     setItems(updatedItems);
    //         // });
    //         addSnapshotListener(q, (querySnapshot) => {
    //             const updatedItems = querySnapshot.docs.map((doc) =>
    //                 doc.data()
    //             );
    //             setItems(updatedItems);
    //         });
    //     })();
    // }, []);

    console.log('drugCoursesLoading', drugCoursesLoading);

    useEffect(() => {
        (async () => {
            setDrugsReady(false);

            if (drugCoursesLoading) return;

            // HACK: Beacause of filters limitaions
            const drugCourseList =
                drugCourseListNoStartDate?.filter((item) => {
                    return (
                        item.startDate.toDate() <= new Date(date) &&
                        (item.endDate?.toDate() >= new Date(date) ||
                            item.endDate === null)
                    );
                }) ?? [];

            console.log('drugCourseListNoStartDate', drugCourseListNoStartDate);
            console.log('drugCourseList', drugCourseList);

            const drugsInGroupsObj = {};

            for (let i = 0; i < drugCourseList.length; i++) {
                const group = await getDoc(drugCourseList[i].group);
                const drug = await getDoc(drugCourseList[i].drug);

                if (!drugsInGroupsObj[group.id]) {
                    drugsInGroupsObj[group.id] = group;
                    drugsInGroupsObj[group.id].drugs = [drug];
                } else {
                    drugsInGroupsObj[group.id].drugs.push(drug);
                }
            }

            setDrugsInGroups(Object.values(drugsInGroupsObj));
            setDrugsReady(true);
        })();
    }, [drugCourseListNoStartDate, date]);

    if (drugCoursesLoading) {
        return (
            <ActivityIndicator
                style={{
                    marginTop: 50
                }}
                size="large"
                color={colors.white_1}
            />
        );
    }

    return (
        <View style={[styles.container, style]}>
            {!drugsInGroups?.length && (
                <Text
                    category={'h5'}
                    style={{
                        color: colors.white_1,
                        marginTop: 50,
                        textAlign: 'center'
                    }}
                >
                    No Items
                </Text>
            )}
            {drugsInGroups.map((groupRef) => {
                const groupData = groupRef.data();

                return (
                    <DrugsGroup
                        name={groupData.name}
                        key={groupRef.id}
                        id={groupRef.id}
                        date={date}
                    >
                        {groupRef.drugs.map((drugRef) => {
                            const drug = drugRef.data();

                            const docToDelete =
                                takenDrugsList?.docs?.find((el) => {
                                    const obj = el.data();

                                    return (
                                        obj.drug.id === drugRef.id &&
                                        obj.group.id === groupRef.id
                                    );
                                }) ?? [];

                            const isChecked = Boolean(docToDelete);

                            return (
                                <DrugItem
                                    name={drug.name}
                                    groupId={drugRef.id}
                                    date={date}
                                    key={drug.id}
                                    isChecked={isChecked}
                                    onPress={() => {
                                        if (isChecked) {
                                            deleteDoc(docToDelete.ref);
                                        } else {
                                            addDoc(takenDrugsRef, {
                                                drug: drugRef.ref,
                                                group: groupRef.ref,
                                                date: new Date(date),
                                                access: [user.uid]
                                            });
                                        }
                                    }}
                                />
                            );
                        })}
                    </DrugsGroup>
                );
            })}
        </View>
    );
});

const styles = StyleSheet.create({
    container: {
        paddingLeft: pageConstants.pagePadding,
        paddingRight: pageConstants.pagePadding,
        height: 'fit-content'
    }
});
