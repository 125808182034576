import { Icon } from '@ui-kitten/components';
import { ReactNode } from 'react';
import { View, Text, Pressable, StyleSheet } from 'react-native';
import { useTailwind } from 'tailwind-rn';

import { pageConstants } from '../../../../components/Page/Page';
import { rootModel } from '../../../../models';
import { colors } from '../../../../styles/colors';
import { iconSizes } from '../../../../styles/iconSizes';

export interface DrugsGroupProps {
    name: string;
    children: ReactNode;
    id: number;
    date: string;
}

export const DrugsGroup = (props: DrugsGroupProps) => {
    const { children, name, id, date } = props;

    const tailwind = useTailwind();

    return (
        <View style={{ marginTop: pageConstants.rowsGap }}>
            <View style={styles.header}>
                <Text style={tailwind('text-white text-xl font-bold')}>
                    {name}
                </Text>
                {Boolean(!rootModel.takenDrug.isAllDrugsInGroupTaken[id]) && (
                    <Pressable
                        onPress={() =>
                            rootModel.takenDrug.takeAllInGroup({
                                drugGroupId: id,
                                date
                            })
                        }
                    >
                        <Icon
                            name={'done-all-outline'}
                            style={{
                                width: iconSizes.l,
                                height: iconSizes.l
                            }}
                            fill={colors.white_1_transparent_50}
                        />
                    </Pressable>
                )}
            </View>
            {children}
        </View>
    );
};

const styles = StyleSheet.create({
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    }
});
